import Api from '../api'

export default {

	/**
	* Get nodes from starting.
	*/
	nodes(modelId, modelType)
	{
		let url = Api.getUrl('network/explore'),
			params = {
				model_type: modelType,
				model_id: modelId
			}

		return Api.client().get(url, {params})
	}
}
import Api from '../api'

export default {

	/**
	* Create a website.
	*/
	create(params = {})
	{
		let url = Api.getUrl('website')

		return Api.client().post(url, params)
	},

	/**
	* Update a website.
	*/
	update(websiteId, params = {})
	{
		let url = Api.getUrl(`website/${websiteId}`)

		return Api.client().post(url, params)
	},

	/**
	* List websites.
	*/
	list(params={})
	{
		let url = Api.getUrl('websites')

		return Api.client().get(url, {params})
	},

	/**
	* Get a website.
	*/
	one(websiteId)
	{
		let url = Api.getUrl(`website/${websiteId}`)

		return Api.client().get(url)
	},

	/**
	 * Get all website articles.
	 */
	articles(websiteId)
	{
		let url = Api.getUrl(`website/${websiteId}/articles`)

		return Api.client().get(url)
	},

	/**
	* Update a website screenshot.
	*/
	screenshot(websiteId, params = {})
	{
		let url = Api.getUrl(`website/${websiteId}/screenshot`)

		return Api.client().post(url, params)
	},
}
import Api from '../api'

export default {

	/**
	* Get a note.
	*/
	one(noteId)
	{
		let url = Api.getUrl(`note/${noteId}`)

		return Api.client().get(url)
	},

	/**
	* Create a note.
	*/
	create(params = {})
	{
		let url = Api.getUrl('note')

		return Api.client().post(url, params)
	},

	/**
	* Delete a note.
	*/
	delete(noteId)
	{
		let url = Api.getUrl(`note/${noteId}`)

		return Api.client().delete(url)
	},

	/**
	* Get a note.
	*/
	update(noteId, params = {})
	{
		let url = Api.getUrl(`note/${noteId}`)

		return Api.client().post(url, params)
	},

}
import Api from '../api'

export default {

	/**
	* Get a quote.
	*/
	one(quoteId)
	{
		let url = Api.getUrl(`quote/${quoteId}`)

		return Api.client().get(url)
	},

	/**
	* Create a quote.
	*/
	create(params = {})
	{
		let url = Api.getUrl('quote')

		return Api.client().post(url, params)
	},

	/**
	* Delete a quote.
	*/
	delete(quoteId)
	{
		let url = Api.getUrl(`quote/${quoteId}`)

		return Api.client().delete(url)
	},

	/**
	 * Get all quote notes.
	 */
	notes(quoteId)
	{
		let url = Api.getUrl(`quote/${quoteId}/notes`)

		return Api.client().get(url)
	},

	/**
	 * Get quote topics.
	 */
	topics(quoteId, _letter="")
	{
		let forLetter = ( _letter ? `/letter/${_letter}` : '' ), 
			url = Api.getUrl(`quote/${quoteId}/topics${forLetter}`)

		return Api.client().get(url)
	},

	/**
	* Get a quote.
	*/
	update(quoteId, params = {})
	{
		let url = Api.getUrl(`quote/${quoteId}`)

		return Api.client().post(url, params)
	},

	/**
	* Get a random quote.
	*/
	random()
	{
		let url = Api.getUrl(`quote/random`)

		return Api.client().get(url)
	},

}
import Api from '../api'

export default {

	/**
	 * Book chart.
	 */
	chart(params = {})
	{
		let url = Api.getUrl('books/chart')

		return Api.client().get(url, { params })
	},

	/**
	 * Save a book cover from URL.
	 */
	importCover(isbn, coverUrl, data={}, config={})
	{
		let url = Api.getUrl(`books/import/cover/${isbn}`)

		data.cover_url = coverUrl

		return Api.client().post(url, data, config)
	},

	/**
	 * Save a book from ISBN.
	 */
	importIsbn(isbn, data={}, config={})
	{
		let url = Api.getUrl(`books/import/isbn/${isbn}`)

		return Api.client().post(url, data, config)
	},

	/**
	 * List books.
	 */
	list(params = {})
	{
		let url = Api.getUrl('books')

		return Api.client().get(url, { params })
	},

	/**
	 * Get a book from ISBN.
	 */
	lookupIsbn(isbn)
	{
		let url = Api.getUrl(`books/lookup/isbn/${isbn}`)

		return Api.client().get(url)
	},

	/**
	 * Get a book from title.
	 */
	lookupTitle(search)
	{
		let url = Api.getUrl(`books/lookup/title/${search}`)

		return Api.client().get(url)
	},

	/**
	 * Get a book.
	 */
	one(bookId, params={})
	{
		let url = Api.getUrl(`book/${bookId}`)

		return Api.client().get(url, { params })
	},

	/**
	 * Get all book notes.
	 */
	notes(bookId)
	{
		let url = Api.getUrl(`book/${bookId}/notes`)

		return Api.client().get(url)
	},

	/**
	 * Get all book quotes.
	 */
	quotes(bookId)
	{
		let url = Api.getUrl(`book/${bookId}/quotes`)

		return Api.client().get(url)
	},

	/**
	 * Get all book user has read.
	 */
	read()
	{
		let url = Api.getUrl(`books/read`)

		return Api.client().get(url)
	},

	/**
	 * Get all book user is reading.
	 */
	reading()
	{
		let url = Api.getUrl(`books/reading`)

		return Api.client().get(url)
	},

	/**
	 * Get all book topics.
	 */
	topics(bookId, _extended = false, _letter = "", params = {})
	{
		let extended = ( _extended ? 'extended' : '' ), 
			forLetter = ( _letter ? `letter/${_letter}/` : '' ),
			url = Api.getUrl(`book/${bookId}/topics/${forLetter}${extended}`)

		return Api.client().get(url, { params })
	},

	/**
	 * Update a book.
	 */
	update(bookId, params = {})
	{
		let url = Api.getUrl(`book/${bookId}`)

		return Api.client().post(url, params)
	},

	/**
	 * Update a book authors.
	 */
	updateAuthors(bookId, params = {})
	{
		let url = Api.getUrl(`book/${bookId}/authors`)

		return Api.client().post(url, params)
	},

}
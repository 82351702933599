import Api from '../api'

export default {

	/**
	 * Get single author info.
	 */
	one(authorId)
	{
		let url = Api.getUrl(`author/${authorId}`)

		return Api.client().get(url)
	},

	/**
	 * List authors.
	 */
	list(params = {})
	{
		let url = Api.getUrl('authors')

		return Api.client().get(url, { params })
	},

}
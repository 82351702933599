import Api from '../api'

export default {

	/**
	* Forward a topic.
	*/
	forward(topicId, targetId)
	{
		let url = Api.getUrl(`topic/${topicId}/forward/${targetId}`)

		return Api.client().post(url)
	},

	/**
	* Hide a topic.
	*/
	hide(topicId)
	{
		let url = Api.getUrl(`topic/${topicId}/hide`)

		return Api.client().post(url)
	},

	/**
	* List all topics.
	*/
	list(_letter="")
	{
		let forLetter = ( _letter ? `/letter/${_letter}` : '' ), 
			url = Api.getUrl(`topics${forLetter}`)

		return Api.client().get(url)
	},

	one(topicId, params = {})
	{
		let url = Api.getUrl(`topic/${topicId}`)

		return Api.client().get(url, { params })
	},

}
import Api from '../api'

export default {

	/**
	* Toggle a genre status for a book.
	*/
	toggle(genreId,bookId)
	{
		let url = Api.getUrl(`genre/${genreId}/toggle/${bookId}`)

		return Api.client().post(url)
	},

	/**
	* List genres.
	*/
	list(params={})
	{
		let url = Api.getUrl('genres')

		return Api.client().get(url, { params })
	},

	/**
	* Get a genre.
	*/
	one(genreId, params={})
	{
		let url = Api.getUrl(`genre/${genreId}`)

		return Api.client().get(url, { params })
	},

}
import Api from '../api'

export default {

	/**
	* List issues.
	*/
	list(params={})
	{
		let url = Api.getUrl('issues')

		return Api.client().get(url, {params})
	},

	/**
	* Get an issue.
	*/
	one(issueId)
	{
		let url = Api.getUrl(`issue/${issueId}`)

		return Api.client().get(url)
	},

	/**
	* Create an issue.
	*/
	create(params = {})
	{
		let url = Api.getUrl('issue')

		return Api.client().post(url, params)
	},

	/**
	* Update an issue.
	*/
	update(issueId, params = {})
	{
		let url = Api.getUrl(`issue/${issueId}`)

		return Api.client().post(url, params)
	},

	/**
	 * Lookup issues.
	 */
	lookup(search)
	{
		let url = Api.getUrl(`issues/lookup/${search}`)

		return Api.client().get(url)
	},

	/**
	 * Get all issue articles.
	 */
	articles(issueId)
	{
		let url = Api.getUrl(`issue/${issueId}/articles`)

		return Api.client().get(url)
	},

	/**
	 * Get all issue notes.
	 */
	notes(issueId, extended=false)
	{
		let ext = extended ? '/extended' : '',
			url = Api.getUrl(`issue/${issueId}/notes${ext}`)

		return Api.client().get(url)
	},

	/**
	 * Get all issue quotes.
	 */
	quotes(issueId)
	{
		let url = Api.getUrl(`issue/${issueId}/quotes`)

		return Api.client().get(url)
	},

	/**
	 * Get all issue topics.
	 */
	topics(issueId, _extended = false, _letter = "")
	{
		let extended = ( _extended ? 'extended' : '' ), 
			forLetter = ( _letter ? `letter/${_letter}/` : '' ),
			url = Api.getUrl(`issue/${issueId}/topics/${forLetter}${extended}`)

		return Api.client().get(url)
	},
}
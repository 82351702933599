import Api from '../api'

export default {

	/**
	* Create an article.
	*/
	create(params = {})
	{
		let url = Api.getUrl('article')

		return Api.client().post(url, params)
	},

	delete(articleId)
	{
		let url = Api.getUrl(`article/${articleId}`)

		return Api.client().delete(url)
	},

	/**
	* Update an article.
	*/
	update(articleId, params = {})
	{
		let url = Api.getUrl(`article/${articleId}`)

		return Api.client().post(url, params)
	},

	/**
	* List articles.
	*/
	list(params={})
	{
		let url = Api.getUrl('articles')

		return Api.client().get(url, {params})
	},

	/**
	* Get an article.
	*/
	one(articleId)
	{
		let url = Api.getUrl(`article/${articleId}`)

		return Api.client().get(url)
	},

	/**
	 * Get all article notes.
	 */
	notes(articleId)
	{
		let url = Api.getUrl(`article/${articleId}/notes`)

		return Api.client().get(url)
	},

	/**
	 * Get all article quotes.
	 */
	quotes(articleId)
	{
		let url = Api.getUrl(`article/${articleId}/quotes`)

		return Api.client().get(url)
	},

	/**
	 * Get all article topics.
	 */
	topics(articleId, _extended = false, _letter = "")
	{
		let extended = ( _extended ? 'extended' : '' ), 
			forLetter = ( _letter ? `letter/${_letter}/` : '' ),
			url = Api.getUrl(`article/${articleId}/topics/${forLetter}${extended}`)

		return Api.client().get(url)
	},
}
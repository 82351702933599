import Api from '../api'

export default {

	/**
	* Get current user favourites.
	*/

	list(params={})
	{
		let url = Api.getUrl('favourites')

		return Api.client().get(url, { params })
	},

	toggle( modelType="", modelId="" )
	{
		let url = Api.getUrl(`favourites/toggle/${modelType}/${modelId}`)

		return Api.client().post(url)
	},

	clearAll( modelType="" )
	{
		let url = Api.getUrl(`favourites/clear/${modelType}`)

		return Api.client().post(url)
	},

}
import Api from '../api'

export default {

	/**
	* Create a newsletter.
	*/
	create(params = {})
	{
		let url = Api.getUrl('newsletter')

		return Api.client().post(url, params)
	},

	/**
	* Update a newsletter.
	*/
	update(newsletterId, params = {})
	{
		let url = Api.getUrl(`newsletter/${newsletterId}`)

		return Api.client().post(url, params)
	},

	/**
	* List newsletters.
	*/
	list(params={})
	{
		let url = Api.getUrl('newsletters')

		return Api.client().get(url, {params})
	},

	/**
	* Get a newsletter.
	*/
	one(newsletterId)
	{
		let url = Api.getUrl(`newsletter/${newsletterId}`)

		return Api.client().get(url)
	},
}
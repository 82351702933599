import Api from '../api'

export default {

	/**
	* List magazines.
	*/
	list(params={})
	{
		let url = Api.getUrl('magazines')

		return Api.client().get(url, {params})
	},

	/**
	* Get a magazine.
	*/
	one(magazineId)
	{
		let url = Api.getUrl(`magazine/${magazineId}`)

		return Api.client().get(url)
	},

	issues(magazineId)
	{
		let url = Api.getUrl(`magazine/${magazineId}/issues`)

		return Api.client().get(url)
	},

	/**
	* Create a magazine.
	*/
	create(params = {})
	{
		let url = Api.getUrl('magazine')

		return Api.client().post(url, params)
	},

	/**
	* Update a magazine.
	*/
	update(magazineId, params = {})
	{
		let url = Api.getUrl(`magazine/${magazineId}`)

		return Api.client().post(url, params)
	},

	/**
	 * Lookup issues.
	 */
	lookup(magazineId, filter = "")
	{
		let url = Api.getUrl(`magazine/${magazineId}/lookup/${filter}`)

		return Api.client().get(url)
	}
	
}
import Api from '../api'

export default {

	/**
	* Get a bookshelf.
	*/
	one(bookshelfId, params={})
	{
		let url = Api.getUrl(`bookshelf/${bookshelfId}`)

		return Api.client().get(url, { params })
	},

	/**
	* Create a bookshelf.
	*/
	create(params={})
	{
		let url = Api.getUrl('bookshelf')

		return Api.client().post(url, null, { params })
	},

	/**
	* Delete a bookshelf.
	*/
	delete(bookshelfId)
	{
		let url = Api.getUrl(`bookshelf/${bookshelfId}`)

		return Api.client().delete(url)
	},

	/**
	* Toggle a bookshelf status for a book.
	*/
	toggle(bookshelfId,bookId)
	{
		let url = Api.getUrl(`bookshelf/${bookshelfId}/toggle/${bookId}`)

		return Api.client().post(url)
	},

	/**
	* Update a bookshelf.
	*/
	update(bookshelfId, params={})
	{
		let url = Api.getUrl(`bookshelf/${bookshelfId}`)

		return Api.client().post(url, null, { params })
	},

	/**
	* List bookshelves.
	*/
	list()
	{
		let url = Api.getUrl('bookshelves')

		return Api.client().get(url)
	},

	/**
	* Sync bookshelves status for a model.
	*/
	sync(bookshelfIds=[], modelType="", modelId="")
	{
		let url = Api.getUrl(`bookshelves/sync/${modelType}/${modelId}`)

		return Api.client().post(url, { ids:bookshelfIds })
	},

}
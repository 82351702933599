import Api from '../api'

export default {

	/**
	* Get exports.
	*/
	list(params={})
	{
		let url = Api.getUrl(`exports`)

		return Api.client().get(url, { params })
	},

	/**
	* Generate one export.
	*/
	generate(type, data={})
	{
		let url = Api.getUrl(`export/${type}`)

		return Api.client().post(url, data)
	},

	/**
	* Get latest export.
	*/
	latest(type)
	{
		let url = Api.getUrl(`export/${type}`)

		return Api.client().get(url)
	},

}
import Api from '../api'

export default {

	create(params={})
	{
		let url = Api.getUrl('tag')

		return Api.client().post(url, null, { params })
	},

	delete(tagId)
	{
		let url = Api.getUrl(`tag/${tagId}`)

		return Api.client().delete(url)
	},

	list()
	{
		let url = Api.getUrl('tags')

		return Api.client().get(url)
	},

	one(tagId)
	{
		let url = Api.getUrl(`tag/${tagId}`)

		return Api.client().get(url)
	},

	sync( tagIds, modelType, modelId )
	{
		let url = Api.getUrl(`tags/sync/${modelType}/${modelId}`)

		return Api.client().post(url, { ids:tagIds })
	},

	toggle( tagId, modelType, modelId )
	{
		let url = Api.getUrl(`tag/${tagId}/toggle/${modelType}/${modelId}`)

		return Api.client().post(url)
	},

	update( tagId, params={})
	{
		let url = Api.getUrl(`tag/${tagId}`)

		return Api.client().post(url, null, { params })
	},

}